<template>
  <div class="conclusion h-100">
    <div class="container h-100 py-5">
      <h3
        class="text-capitalize pt-2 pt-md-5"
        data-aos="fade-down-right"
        data-aos-easing="ease-in-out"
        data-aos-duration="400"
      >
        {{ this.conclusion.Label }}
      </h3>
      <div class="row row-wrapper align-items-center justify-content-center">
        <div class="row py-5">
          <div class="col-12">
            <div
              class="card shadow"
              data-aos="zoom-out"
              data-aos-easing="linear"
              data-aos-duration="600"
            >
              <div class="card-body p-3 p-md-5">
                <span v-html="this.conclusion.conclusion"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Conclusion",
  data() {
    return {
      conclusion: [],
    };
  },
  methods: {
    getConclusion() {
      this.conclusion = this.$store.getters["conclusion"];
    },
  },
  mounted() {
    this.getConclusion();
    this.$store.dispatch("denyNavigationStatus");
    this.$store.dispatch( 'setInstructions', '' )
    this.$store.dispatch( 'trialTime')
  }
};
</script>

<style scoped>
</style>
